import React, { useState, useRef  } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function ContactForm() {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const recaptchaRef = useRef();

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [id]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const reCaptcha_token = await recaptchaRef.current.executeAsync();
    const newErrors = validateFormData();
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0 && reCaptcha_token) {
      sendEmail(formData, reCaptcha_token);
    }
  };

  const validateFormData = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Ime i prezime je obavezno";
    } else if (formData.name.length < 3) {
        newErrors.name = "Ime i prezime mora imati najmanje 3 karaktera";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email adresa je obavezna";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email adresa nije validna";
    }
    if (!formData.subject.trim()) {
      newErrors.subject = "Naslov je obavezan";
    } else if (formData.subject.length < 3) {
        newErrors.subject = "Naslov mora imati najmanje 3 karaktera";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Poruka je obavezna";
    } else if (formData.message.length < 3) {
        newErrors.message = "Poruka mora imati najmanje 3 karaktera";
    }
    return newErrors;
  };

  const sendEmail = (form_data, reCaptcha_token) => {
    // Kreiraj email body string
    const emailBody = `
      Poštovani,

      Dobili ste novu poruku putem kontakt forme sa vaše web stranice. U nastavku su informacije koje je korisnik unio:

      Ime i prezime: ${form_data.name}
      Email adresa: ${form_data.email}
      Naslov: ${form_data.subject}
      Poruka: ${form_data.message}
    `;

    // Definiši podatke za slanje
    const data = {
        to: 'amelnurkic@gmail.com',
        subject: 'Novi kontakt sa vaše web stranice',
        text: emailBody,
        recaptchaToken: reCaptcha_token
    };

    fetch(`${API_ENDPOINT_URL}send-email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': API_TOKEN
        },
        body: JSON.stringify(data),
    })
    .then((response) => {
        if (response.ok) {
            setIsEmailSent(true);
        } else {
            throw new Error('Error sending email');
        }
    })
    .catch((error) => {
        console.log(error);
    });
};

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Ime i prezime
        </label>
        <input
          type="text"
          className={`form-control ${errors.name && "is-invalid"}`}
          id="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <div className="invalid-feedback">{errors.name}</div>
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email adresa
        </label>
        <input
          type="email"
          className={`form-control ${errors.email && "is-invalid"}`}
          id="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <div className="invalid-feedback">{errors.email}</div>
      </div>
      <div className="mb-3">
        <label htmlFor="subject" className="form-label">
          Naslov
        </label>
        <input
          type="text"
          className={`form-control ${errors.subject && "is-invalid"}`}
          id="subject"
          value={formData.subject}
          onChange={handleInputChange}
          required
        />
        <div className="invalid-feedback">{errors.subject}</div>
      </div>
      <div className="mb-3">
        <label htmlFor="message" className="form-label">
          Poruka
        </label>
        <textarea
          className={`form-control ${errors.message && "is-invalid"}`}
          id="message"
          rows="3"
          value={formData.message}
          onChange={handleInputChange}
          required
        ></textarea>
        <div className="invalid-feedback">{errors.message}</div>
      </div>
      <ReCAPTCHA 
        sitekey={RECAPTCHA_SITE_KEY}
        size="invisible"
        ref={recaptchaRef}
      />
      <div className="mb-3 text-center">
        {isEmailSent ? (
            <div className="alert alert-success" role="alert">
                Hvala na poruci! Uskoro ćemo Vas kontaktirati.
            </div>
        ) : (
        <button type="submit" className="btn btn-primary btn-lg submit-btn-contact-us">
          Pošalji
        </button>
        )}
      </div>
    </form>
  );
}

export default ContactForm;
