import React from 'react';
import { FaSolarPanel, FaBuilding, FaHome, FaLightbulb, FaFileAlt, FaInfoCircle, FaChartBar, FaMoneyBillAlt, FaClipboardCheck, FaTools, FaCheckCircle, FaPhoneAlt, FaEnvelope, FaClock, FaMapMarkerAlt} from 'react-icons/fa';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';

function HomePage() {
  return (
    <div className="container-fluid">
      <Header />
        <div className="container position-relative carousel-container">
              <img src="/images/electrical-designing.jpg" alt="" className="img-fluid electrical-designing-img"/>
              <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center" style={{ zIndex: 1 }}>
                <h1 className="text-light text-on-image">BAN PROJEKT GRAČANICA</h1>
                <p className="text-light text-on-image text-paragraph">ELEKTRO POJEKTOVANJE / INŽENJERING / KONSALTING</p>
            </div>
            <div className="position-absolute top-0 start-0 w-100 h-100 image-shadow"></div>
        </div>
        <div className="container">
          <section id="about-us" className="container-fluid sections">
              <div className="row">
                <div className="col-12">
                  <h2 className="text-center header-titles">Ko smo mi</h2>
                  <p className="">BAN Projekt Gračanica osnovan je 24.06.2022, kao rezultat dugogodišnjeg radnog iskustva.</p>
                  <p className="">Elektroinžinjer sa respektabilnim iskustvom i položenim stručnim ispitom, koji uz aktivnu
                    saradnju sa spoljnim saradnicima, kvalitetno po važećim propisima/standardima i
                    preporukama izvode projekte:
                  </p>
                  <div className="row g-4 what-we-do-items">
                    <div className="col-md-4">
                      <div className="card text-center h-100">
                        <div className="card-body">
                          <FaSolarPanel size={80} />
                          <h5 className="card-title">Projektovanje solarnih fotonaponskih elektrana</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center h-100">
                        <div className="card-body">
                          <FaBuilding size={80} />
                          <h5 className="card-title">Elektroenergetskih instalacija industrijskih i privrednih subjekata</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center h-100">
                        <div className="card-body">
                          <FaHome size={80} />
                          <h5 className="card-title">Elektroenergetskih instalacija stambenih objekata</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center h-100">
                        <div className="card-body">
                          <FaLightbulb size={80} />
                          <h5 className="card-title">Ulične-javne rasvjete, šetnice, trgove i bulevare</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center h-100">
                        <div className="card-body">
                          <FaFileAlt size={80} />
                          <h5 className="card-title">Izrada elaborata električnih instalacija</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center h-100">
                        <div className="card-body">
                          <FaBuilding size={80} />
                          <h5 className="card-title">Elektroenergetskih instalacija poslovnih i skladišnih objekata</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 id="services" className="text-center header-titles">Šta nudimo</h2>
                  <p className="text-center">U okviru inženjering usluga, našim investitorima možemo ponuditi slijedeće usluge: </p>
                  <div className="row g-4 engineering-services-items">
                    <div className="col-md-4">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaInfoCircle size={80} />
                          <h5 className="card-title">Neophodne informacije, savjete i preporuke u pripremi tehničke dokumentacije</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaChartBar size={80} />
                          <h5 className="card-title">Stručnu analizu predmjera i predračuna u fazi odabira i nabavke projektovane opreme</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaMoneyBillAlt size={80} />
                          <h5 className="card-title">Procjena kompletne investicije i planiranja troškova</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaClipboardCheck size={80} />
                          <h5 className="card-title">Stručni nadzor nad izvođenjem elektroinstalacionih radova</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaTools size={80} />
                          <h5 className="card-title">Koordiniranje svih procesa nad projektima</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className="text-center header-titles">Konsalting usluge</h2>
                  <div className="row g-4 what-we-do-items">
                    <div className="col-md-4">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaInfoCircle size={80} />
                          <h5 className="card-title">Pomoć u pribavljanju pravne i tehničke dokumentacije od strane nadležnih institucija</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaCheckCircle size={80} />
                          <h5 className="card-title">Pribavljanje potrebnih elektroenergetskih saglasnosti od strane nadležnih ED</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaFileAlt size={80} />
                          <h5 className="card-title">Izrada projekata i potrebnih elaborata</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </section>
          <section className="container-fluid sections">
              <div className="row contact-us-section-container">
              <h2 id="contact-us" className="text-center header-titles">Kontaktirajte nas</h2>
                <div className="col-md-6">
                  <div className="row g-4 contact-us-items">
                    <div className="col-md-6">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaPhoneAlt size={30} />
                          <h5 className="card-title">Telefon</h5>
                          <a className="card-text" href="tel:+38762612704">+38762612704</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaEnvelope size={30} />
                          <h5 className="card-title">Email</h5>
                          <a className="card-text" href="mailto:nihadtuholjak@gmail.com">nihadtuholjak@gmail.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-4 contact-us-items">
                    <div className="col-md-6">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaMapMarkerAlt size={30} />
                          <h5 className="card-title">Adresa</h5>
                          <a 
                            className="card-text" 
                            href="https://goo.gl/maps/mPsKbz1fG5GQHtLs6" 
                            target="_blank">
                              Bosanskih kraljeva b.b. 75320 Gračanica, Bosna i Hercegovina 
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card text-center">
                        <div className="card-body">
                          <FaClock size={30} />
                          <h5 className="card-title">Radno vrijeme</h5>
                          <p className="card-text">Ponedeljak: 14:00 - 18:00</p>
                          <p className="card-text">Utorak - Petak: 8:00 - 10:00</p>
                          <p className="card-text">Subota: 8:00 - 16:00</p>
                          <p className="card-text">Nedjelja: Neradna</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row g-4 contact-us-items">
                    <div className="col-md-12">
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
          </section>
        </div>

      <Footer />
    </div>
  );
}

export default HomePage;